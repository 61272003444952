<script setup lang="ts">
// Types
export interface ListCardItem {
  key: string;
  img?: string;
  title: string;
  infos?: {
    key: string;
    label: string;
    value: string;
  }[];
}

const emit = defineEmits<{
  (e: 'click-item', item: ListCardItem): void;
}>();
const props = defineProps<{
  items: ListCardItem[];
  actions?: {
    key: string;
    label: string;
  }[];
  clickable?: boolean;
}>();

// Methods
const handleClickItem = (item: ListCardItem) => {
  if (props.clickable) {
    emit('click-item', item);
  }
};
</script>
<template>
  <ul role="list" class="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8">
    <li
      v-for="(item, index) in items"
      :key="`item-${index}-${item.title}`"
      class="overflow-hidden rounded-xl border border-gray-200 hover:border-primary hover:shadow-md cursor-pointer"
      @click="handleClickItem(item)"
    >
      <div class="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
        <img
          v-if="item.img"
          :src="item.img"
          :alt="item.title"
          class="size-12 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10"
        />
        <div v-else class="flex items-center justify-center rounded-lg bg-gray-100 size-12 p-2">
          <ui-icon name="Image" class="w-full text-gray-500" />
        </div>

        <div class="text-sm/6 font-medium text-gray-900">{{ item.title }}</div>
      </div>
      <dl class="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm/6">
        <div v-for="(info, index) in item.infos" :key="`info-${index}-${info.key}`" class="flex justify-between gap-x-4 py-3">
          <dt class="text-gray-500">{{ info.label }}</dt>
          <dd class="text-gray-700">
            {{ info.value }}
          </dd>
        </div>
      </dl>
    </li>
  </ul>
</template>
