<script lang="ts" setup>
import type { ListCardItem } from '~/components/nrjx/list/card.vue';
import ClientModal from '../modal/Client.vue';

// Composables
const { getClients } = useClientApi();

// Composables
const router = useRouter();

// Data
const search = ref('');
const createClientModal = ref(false);
// Load data
const { data: clients } = useAsyncData('clients', () => getClients());

// Methods
const handleClickRow = (item: ListCardItem) => {
  router.push(`/admin/clients/${item.key}`);
};

// Computed
const items = computed((): ListCardItem[] => {
  return (
    clients.value?.map((client) => ({
      key: client.id.toString(),
      title: client.name,
      infos: [{ key: 'name', label: 'Name', value: client.name }],
    })) ?? []
  );
});

const filteredItems = computed(() => {
  return items.value.filter((item) => item.title.toLowerCase().includes(search.value.toLowerCase()));
});
</script>
<template>
  <ui-card :title="$t('global.clients')">
    <template #header>
      <ui-search-bar v-model="search" />
      <ui-button left-icon="Plus" @click="createClientModal = true">{{ $t('admin.add_client') }}</ui-button>
    </template>

    <!-- Table -->
    <nrjx-list-card :items="filteredItems ?? []" clickable @click-item="handleClickRow" />

    <!-- Modal -->
    <client-modal v-model="createClientModal" />
  </ui-card>
</template>
