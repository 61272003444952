<script lang="ts" setup>
// Components
import ClientList from '../../components/list/client.vue';

// Composables
const { t } = useI18n();

// Data
const breadcrumb = computed(() => [
  {
    name: t('global.admin'),
    url: '/admin',
  },
]);
</script>
<template>
  <ui-page :title="$t('global.clients')" :breadcrumb="breadcrumb">
    <client-list />
  </ui-page>
</template>
