<script setup lang="ts">
import * as yup from 'yup';
import { useForm } from 'vee-validate';

// Props
const modal = defineModel<boolean>({ required: true });

// Composables
const { createClient } = useClientApi();
const { handleSubmit } = useForm({
  validationSchema: yup.object({
    name: yup.string().required(),
  }),
});

// Methods
const onSubmit = handleSubmit(async (values) => {
  await createClient(values);
  modal.value = false;
});
</script>
<template>
  <nrjx-modal v-model="modal" width="500" :title="$t('client.create_client')">
    <ui-form-input-text name="name" :label="$t('global.name')" :placeholder="$t('client.client_name')" />

    <template #footer>
      <ui-button @click="onSubmit">{{ $t('global.save') }}</ui-button>
    </template>
  </nrjx-modal>
</template>
